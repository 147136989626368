import React from 'react';
import { mq } from '../../theme';

const PADDING_HORIZONTAL = 24;

const ContainerContentLeft = ({ size = 50, noMarge, ...rest }) => {
  return (
    <div
      {...rest}
      css={mq({
        flex: (Array.isArray(size) ? size : [size]).map(s => `0 ${s}%`),
        marginTop: noMarge && [-80, -30],
        marginLeft: noMarge && -PADDING_HORIZONTAL,
        marginBottom: noMarge && [-85, -30],
        boxSizing: 'border-box'
      })}
    />
  );
};

const ContainerContentRight = ({ size = 50, noMarge, ...rest }) => {
  return (
    <div
      {...rest}
      css={mq({
        flex: (Array.isArray(size) ? size : [size]).map(s => `0 ${s}%`),
        marginTop: noMarge && [-80, -30],
        marginRight: noMarge && -PADDING_HORIZONTAL,
        marginBottom: noMarge && [-85, -30],
        boxSizing: 'border-box'
      })}
    />
  );
};

const Container = ({
  id,
  fluid,
  background,
  color,
  padding = true,
  children,
  ...rest
}) => {
  return (
    <div id={id} style={{ backgroundColor: background, color }}>
      <div css={{ position: 'relative' }}>
        <div
          css={{
            margin: `0 auto`,
            maxWidth: !fluid ? 1140 : 1500
          }}
        >
          <div
            {...rest}
            css={mq({
              flexDirection: ['row', 'column'],
              boxSizing: 'border-box',
              padding: padding && [
                `80px ${PADDING_HORIZONTAL}px 85px`,
                `30px ${PADDING_HORIZONTAL}px`
              ],
              display: 'flex'
            })}
          >
            {children}
          </div>
        </div>
      </div>
    </div>
  );
};

Container.ContentLeft = ContainerContentLeft;
Container.ContentRight = ContainerContentRight;

export default Container;
