import React from 'react';
import { colors, mq, fontSizes } from '../../theme';

export const Heading2 = ({
  light = false,
  primary,
  maxWidth,
  textAlign,
  spanColor = colors.brandColor,
  ...rest
}) => {
  const HeadTag = primary ? 'h1' : 'h2';
  return (
    // eslint-disable-next-line jsx-a11y/heading-has-content
    <HeadTag
      {...rest}
      css={mq({
        textAlign,
        maxWidth,
        fontSize: [34, fontSizes.larger],
        wordSpacing: 2,
        marginBottom: 10,
        color: light ? colors.neutralDarker : colors.neutralWhite,
        lineHeight: ['45px', '38px'],
        '& > span': {
          color: spanColor
        }
      })}
    />
  );
};

export const Heading3 = ({
  light = false,
  maxWidth,
  textAlign,
  secondary,
  ...rest
}) => {
  return (
    // eslint-disable-next-line jsx-a11y/heading-has-content
    <h2
      {...rest}
      css={mq({
        textAlign,
        maxWidth,
        fontSize: 27,
        wordSpacing: 2,
        marginBottom: 10,
        color: light ? colors.neutralDarker : colors.neutralWhite,
        lineHeight: '45px',
        '& > span': {
          color: secondary ? colors.neutralWhite : colors.brandColor
        }
      })}
    />
  );
};

export const HeadingDivider = props => {
  return (
    <div
      {...props}
      css={mq({
        marginRight: 10,
        width: 60,
        backgroundColor: colors.brandColor,
        display: 'inline-block',
        borderRadius: 3,
        height: 5,
        marginTop: [30, 10],
        marginBottom: [30, 20],
        marginLeft: [10, 0]
      })}
    >
      &nbsp;
    </div>
  );
};

export const Paragraph = ({ ...rest }) => {
  return (
    <p
      {...rest}
      css={{
        marginBottom: 28,
        fontSize: fontSizes.large,
        lineHeight: '25px'
      }}
    />
  );
};
