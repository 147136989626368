import React from 'react';
import { gtag } from '../../helpers/gtag.js';
import { colors, fontSizes, fontWeights, mq } from '../../theme';
import Link from '../Link';

const FooterBanner = () => (
  <>
    <p
      css={mq({
        backgroundColor: colors.brandColor,
        textAlign: ['center', 'left'],
        margin: `0 auto`,
        padding: '40px 19px',
        lineHeight: '48px',
        color: colors.neutralDarker,
        fontWeight: fontWeights.semiBold,
        fontSize: fontSizes.larger
      })}
    >
      Un gain de temps mesurable. Une bibliothèque inégalée.
      <br css={mq({ display: ['block', 'none'] })} />
      <Link
        onClick={() => {
          gtag('event', 'Click', {
            event_category: 'ExternalLink',
            event_label: `Je demande ma démo gratuite (Footer)`
          });
        }}
        href="https://info.web2vi.com/demande-de-d%C3%A9monstration"
      >
        <u>Je demande ma démo gratuite</u>
      </Link>{' '}
    </p>
  </>
);

export default FooterBanner;
